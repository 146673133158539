<template>
  <div class="skb">
    <iframe
      frameborder="0"
      name="Iframe1"
      :src="url"
      width="100%"
      height="100%"
      v-if="isSkb"
    >
      您的浏览器不支持嵌入式框架，或者当前配置为不显示嵌入式框架。
    </iframe>
    <div style="width: 100%; height: 100%" v-else>
      <el-image style="width: 100%; height: 100%" :src="pngUrl"> </el-image>
      <div class="titleCenter">暂未开通,请联系客服开通拓客宝</div>
    </div>
  </div>
</template> 
  
 <script>
import { skbLogin, getSkbUser, selectAccount } from "@/api/skb";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { request } from "http";
export default {
  data() {
    return {
      url: null,
      isSkb: false,
      pngUrl: require("@/assets/images/nullskb.png"),
      // isSkb: true,
      // url: 'http://syshb.cddmt.cn/login',

    };
  },
  created() {
    this.toSkb();
  },
  mounted() {},
  methods: {
    async toSkb() {
      // let res1 = await selectAccount({
      //   page: "1",
      //   size: "10",
      // });
      // console.log("当前公司uid'", res1.data.data);
      // let data = res1.data.data;
      let res = await skbLogin({});
      // console.log("搜客宝", res);
      if (res.data.statusCode == "00000") {
        this.isSkb = true;
        this.url = `https://skb.cddmt.cn/login?access_token=${res.data.data.token}&redirect=%2fsearch`;
      } else {
        Message.error(res.data.message);
      }
      // console.log("搜客宝链接", this.url);
    },
  },
};
</script>
 
<style lang="less">
.skb {
  position: relative;
  width: 100%;
  height: 100%;
  .el-image__inner {
    -webkit-user-drag: none;
  }

  .titleCenter {
    position: absolute;
    left: 40%;
    top: 45%;
    font-size: 26px;
    padding: 50px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  }
}
</style>